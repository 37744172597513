$colorActivity: #FF0037;
$colorGravity: #141928;
$colorElectricity: #96FFF5;
$colorGray: #959595;

// ******************************************************
// Bootstrap color overrides
// ******************************************************
$primary: $colorElectricity;
$secondary: $colorGravity;
$btn-disabled-opacity: 0.40;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

// ******************************************************
// Fonts
// ******************************************************
@font-face {
  font-family: "Bandwidth";
  src: local("Bandwidth"), url("./fonts/BandwidthDisplay_Rg.otf") format("opentype");
}
@font-face {
  font-family: "ObjectSans";
  src: local("ObjectSans"), url("./fonts/ObjectSans/otf/PPObjectSans-Regular.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "ObjectSans";
  src: local("ObjectSans"), url("./fonts/ObjectSans/otf/PPObjectSans-Bold.otf") format("opentype");
  font-weight: bold;
}

// ******************************************************
// Styles
// ******************************************************
body {
  background: #07080F;
  color: #fff;
  font-size: 16px;
  padding-bottom: 2rem;
  font-family: 'ObjectSans';
  font-size: 1rem;
  line-height: 1.5rem;
}
.header {
  background: $colorGravity;
  padding: 1rem;
  text-align: center;

  .app-logo {
    display: inline-block;
    vertical-align: middle;
    max-height: 100px;
  }
  h1 {
    display: inline-block;
    vertical-align: middle;
    margin-left: 1rem;
    color: #fff;
    font-family: 'Bandwidth';
  }
}
#map {
  height: 600px;
}

.radio-buttons-group {
  margin-bottom: 2rem;
  margin-top: 1rem;
  .radio-buttons {
    display: inline-block;
    label {
      display: inline-block;
      background: transparent;
      color: #fff;
      padding: 0.2rem 0.5rem;
      width: 5rem;
      text-align: center;
      border: solid 1px $colorElectricity;
      cursor: pointer;
    }
    input.form-check-input {
      display: none;
    }
    input.form-check-input:checked+label {
      background: $colorElectricity;
      color: #07080F;
    }
  }
}

footer {
  background-color: $colorGravity;
  font-size: 0.8rem;
  padding: 1rem;
  margin-top: 5rem;
  
  p {
    margin: 0;
  }
}

form {
  .form-label {
    text-transform: uppercase;
  }
  input[type=text],
  input[type=password] {
    background: #222633;
    border: 1px solid #000;
    color: #fff;
    &::placeholder {
      color: $colorGray;
    }
  }
  .row > button {
    width: auto;
    margin-right: calc(var(--bs-gutter-x) * 0.5);
    margin-left: calc(var(--bs-gutter-x) * 0.5);
    padding: 0.3rem 4rem;
  }
}
.btn {
  &.btn-secondary {
    border-color: $colorElectricity;
    color: $colorElectricity;
  }
}

.list-item {
  border-bottom: #000 solid 2px;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  .employee {
    font-weight: bold;
  }
}

.text-right {
  text-align: right;
}

.recognitions-page {
  ul {
    list-style: none;
  
    li a {
      display: inline-block;
      padding: 1rem;
    }
  }
  .text-right {
    margin: -4rem 0 2rem;
  }
}

table {
  thead {
    font-weight: bold;
    background: #222633;
  }
  tbody {
    tr {
      &:hover {
        background-color: #000;
      }
    }
  }
  td {
    border: 1px solid #666;
    padding: 0.2rem 0.5rem;
  }
}

@import "./components/card/card.scss";